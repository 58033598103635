a,
a:link,
a:hover,
a:visited,
a:active {
  text-decoration: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
a.js {
  border-bottom: 1px #993f02 dashed;
}
a {
  color: #2040a0;
  border-bottom-color: rgba(32, 64, 160, 0.3);
}
a:visited {
  color: #804080;
  border-bottom-color: rgba(128, 64, 128, 0.3);
}
a:hover,
a.hover {
  color: #f33;
  border-bottom-color: #f99;
}
a:hover img,
a.hover img {
  border-color: #f99;
}
body {
  background-color: #fff;
  width: 375px;
  margin: 4px auto;
  font: normal 16px/32px Helvetica, sans-serif;
}
.icon-make,
.icon-site {
  transform: scale(0.5);
  position: absolute;
  left: -2px;
  top: -3px;
}
.icon-site {
  top: 6px;
  left: -3px;
}
table {
  width: 100%;
}
tbody td {
  line-height: 18px;
  padding: 7px 0;
}
tbody tr:hover {
  background-color: #f0f0f0;
}
tbody tr.star {
  background-color: #f4f2cc;
}
tbody tr.star:hover {
  background-color: #e6e4bf;
}
tbody tr.loading {
  background-color: #f9f9e4;
}
tbody tr.loading:hover {
  background-color: #eae9d7;
}
th {
  font-weight: bold;
  text-align: left;
  transition: opacity 0.3s ease-in-out;
  position: sticky;
  top: 0;
  z-index: 2;
}
.blur-top {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  height: 30px;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.6);
}
.make {
  width: 29px;
  padding-left: 10px;
}
td.make {
  position: relative;
}
.make div {
  display: inline-block;
  vertical-align: text-bottom;
  font-size: 18px;
}
th.year {
  left: 2px;
}
.price {
  text-align: right;
  font-style: italic;
}
th.price {
  left: 12px;
}
.change {
  padding: 0 12px 0 8px;
  text-align: right;
  font-style: italic;
  color: #666;
}
.time {
  width: 116px;
}
td.time {
  width: 100px;
  padding-left: 16px;
  position: relative;
}
.newcars {
  display: block;
  position: fixed;
  z-index: 3;
  left: 50%;
  margin-left: 100px;
  top: 20px;
  background: rgba(130, 255, 130, 0.9);
  color: white;
  border-radius: 50%;
  box-shadow: 0 0 0 5px rgba(130, 255, 130, 0.3);
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
}
.newcars,
.newcars:hover,
.newcars:active,
.newcars:visited {
  color: white;
  border: none;
  text-decoration: none;
}
a.newcars {
  border: none;
}
.hidden {
  display: none;
}
.login {
  padding: 40px;
}
.login input {
  box-sizing: border-box;
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 1;
  outline: none;
  color: #333;
  appearance: none;
}
.login label {
  display: block;
  margin: 37px 0 4px;
  color: #333;
  font-size: 16px;
  font-weight: 300;
}
.login input[type='text'],
.login input[type='password'] {
  background: none;
  border: none;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 1px solid #333;
  font-size: 20px;
  line-height: 1;
  font-weight: 100;
  padding: 9px 9px;
  height: 50px;
}
.login input[type='text']:focus,
.login input[type='password']:focus {
  border-color: inherit;
  box-shadow: none;
  background: #f6f6f7;
  transition: background-color linear 0.2s;
}
.login input[type='submit'] {
  margin-top: 30px;
  border-radius: 0;
  background-color: #df3e33;
  border: none;
  color: white;
  padding: 20px;
  cursor: pointer;
}
.login input[type='submit']:hover {
  background-color: #ef635d;
}
.login input[type='submit']:active {
  background-color: #9b281f;
}
.sites {
  font-size: 14px;
  color: #999;
}
.sites .site {
  margin: 0 3px;
}
.sites .site:first-child {
  margin-left: 11px;
}
.sites a {
  color: inherit;
  text-decoration: none;
  border: none;
}
.settings-link {
  display: none;
  position: absolute;
  opacity: 0.9;
  background-color: white;
}
.car-icon {
  cursor: pointer;
}
.form-login {
  margin: 1rem 0;
  text-align: center;
}
.form-login input,
.form-login button {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  max-width: 100px;
  margin: 0;
  border: 1px solid transparent;
  border-radius: 0;
}
.form-login input {
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
}
.form-login input[type='text'] {
  position: relative;
  left: 1px;
}
.form-login button {
  position: relative;
  right: 1px;
}
.form-login button {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-login input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  position: relative;
  z-index: 2;
}
.form-login button:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  outline: 0;
  z-index: 2;
}
.form-login button:hover {
  background-color: #0069d9;
  border-color: #0062cc;
}
.form-login button:active {
  background-color: #0062cc;
  border-color: #005cbf;
}
.form-logout {
  position: absolute;
  top: 4px;
  z-index: 10;
  left: 50%;
  margin-left: 125px;
}
.form-logout button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  font-size: 1rem;
  opacity: 0.4;
}
.form-logout button:hover,
.form-logout button:active {
  opacity: 1;
}
.alert {
  display: inline-block;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #202124;
    color: #bdc1c6;
  }
  a {
    color: #8ab4f8;
  }
  .blur-top {
    background-color: rgba(32, 33, 36, 0.6);
  }
  tbody tr:hover {
    background-color: #3c3d44;
  }
  .change {
    color: #6c757a;
  }
}
